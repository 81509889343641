<template>
    <div class="bg-img-custom p-4">
        <!-- <PWAPrompt :promptOnVisit="1" :timesToShow="3" :permanentlyHideOnDismiss="false" /> -->
        <div class="justify-content-center">
            <div class="row">
                <div class="col-lg-12 p-5 text-center">
                    <div class="mx-auto mb-5">
                        <a href="/">
                            <img src="@assets/images/logo.png" alt height="48" />
                        </a>
                        <h6 class="text-white">Version: {{ currentVersion }}</h6>
                        <template v-if="$store.getters.loggedInUser">
                            <h4 class="text-white">Welcome ! {{ $store.getters.loggedInUser.name }}</h4>
                        </template>
                    </div>
                </div>
                <div class="col-lg-12 mb-4">
                    <div class="text-center mb-4">
                        <a class="mr-2" href="https://play.google.com/store/apps/details?id=com.tawcs.onsite.app"
                            target="_blank">
                            <img src="@assets/images/app/google-play-badge.png"
                                style="width: 45%; max-width: 200px;" /></a>
                        <a href="https://apps.apple.com/au/app/tawcs-enterprise-on-site/id1637364665?platform=iphone"
                            target="_blank">
                            <img src="@assets/images/app/apple-store-badge-.png"
                                style="width: 45%; max-width: 200px;" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bar p-2" v-if="currentLocation">
            <h4 class="text-white">{{ currentLocation.name }}</h4>
            <b-button id="goFS" @click="needhelp" block variant="danger">I need help !</b-button>
            <b-button id="goFS" @click="viewProcedures" block variant="primary">View
                Procedures</b-button>
        </div>
        <prompt-user :openModal="openUserModal" @closeMe="closeNewUser"></prompt-user>
    </div>
</template>

<script>

import {
    externalData
} from '@/src/state/api/pwaexternal';

import {
    authenticateData
} from '@/src/state/api/authenticate';

import {
    version
} from "@/package.json";

import promptUser from './promptUser.vue'

export default {
    page: {
        title: 'Lets get started',
    },
    data() {
        return {
            deferredPrompt: null,
            currentVersion: version,
            currentLocation: null,
            openUserModal: false,
            mustHaveUser: false
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    components: {
        promptUser
    },
    async created() {
        await this.loadLocation()
    },
    methods: {
        async loadLocation() {
            this.currentLocation = await externalData.searchLocationByCode({
                qrCode: this.id,
                locationId: ''
            })
            this.mustHaveUser = this.currentLocation.mustHaveUser;
            this.$store.dispatch("setLoggedInLocation", this.currentLocation);

            if (this.mustHaveUser && (!this.$store.getters.loggedInUser)) {
                this.openUserModal = true;
            }
        },
        async viewProcedures() {
            if (this.$store.getters.loggedInUser || !this.mustHaveUser) {
                this.$router.push(`/openapp/${this.currentLocation.keyId}/default-procedure`)
            } else {
                this.openUserModal = true;
            }
        },
        async needhelp() {
            if (this.$store.getters.loggedInUser) {
                await this.createUser();
                this.$router.push(`/openapp/${this.currentLocation.keyId}/default-messaging`)
                return;
            }
            this.openUserModal = true;
        },
        async createUser() {
            var result = await authenticateData.createUser({
                mobileNumber: this.$store.getters.loggedInUser.mobile,
                name: this.$store.getters.loggedInUser.name,
                requestDateTime: new Date(),
                ownerCompanyId: this.currentLocation.companyKeyId,
                locationId: this.currentLocation.keyId
            })
            if (result.succeeded) {
                this.$store.dispatch("setLoggedInUser", {
                    ...this.$store.getters.loggedInUser,
                    token: result.data.token
                });
            }
        },
        closeNewUser() {
            console.log("i got exec");            
            this.openUserModal = false
        }
    }
}
</script>
